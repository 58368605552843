import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-customization',
  templateUrl: './customization.component.html',
  styleUrls: ['./customization.component.css'],
})

export class CustomizationComponent implements OnInit {
  svg:SafeHtml;
  UploadImageUrl;
  productSelected={
    index: '',
    content:'',
    statux:false,
    groups :[]
  }
  uploads=localStorage.getItem('uploads') != null ? JSON.parse(localStorage.getItem('uploads')) : [];
  productId;
  svgSelected = false;
  svgSelectedColor = '';
  svgSelectClass;
  layerSelect={
    color: '#ffffff',
    size: 50,
    family: '',
    weight: '',
  };
  comment = "'<h1>hello</h1>'"
  products;
  product ;
  customizeProducts=localStorage.getItem('customizeProducts') != null ? JSON.parse(localStorage.getItem('customizeProducts')) : [];
  // customizeStorage = localStorage.getItem('customize') != null ? JSON.parse(localStorage.getItem('customize')) : []
  groups = this.productSelected.index.toString().length > 0 ? this.customizeProducts[this.productSelected.index].groups : [];
  router: any;
  // add product to customize
  AddProduct(product){
    
    if(this.customizeProducts.length >= 1 
      && this.customizeProducts[this.customizeProducts.length - 1].groups.length == 0 
      && this.customizeProducts[this.customizeProducts.length - 1].change == false){
      this.customizeProducts.splice(this.customizeProducts.length - 1,1);
    }else {
      this.customizeProducts.push(product);
      
      this.selectproduct(this.customizeProducts.length - 1)
    }
    this.setGroupsInStorage();

  }
  setGroupsInStorage(){
    if(this.productSelected.index.toString().length > 0){
      
    this.groups = this.customizeProducts[this.productSelected.index].groups;
      this.customizeProducts[this.productSelected.index].groups = this.groups
    }
    // localStorage.setItem('customize',JSON.stringify(this.groups))
    localStorage.setItem('customizeProducts',JSON.stringify(this.customizeProducts))
    
  }
  //color convert to rgb
  convertColor(e,color){
    var colorValue = color ? color.replace(/rgb\(/g,'').replace(/\)/g,'').replace(/ /g,'').split(",") : '';
    function componentToHex(c) {
      var hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    }

    function rgbToHex(r, g, b) {
      return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }
    var colorConvert = colorValue.length != 0 ? rgbToHex(Number(colorValue[0]),Number(colorValue[1]),Number(colorValue[2])) : '#fffff'
    return color.indexOf('#') != -1 ? color : colorConvert
  }

// preview image
uploadImage(e) {
  var input = e.target
  if (input.files && input.files[0]) {
    
    var reader = new FileReader();
   
    
    reader.onloadend = (e) => {
      
    this.uploads.push(
      {
        layers:[
          {
            type:'img',
            upload:true,
            content:e.target.result,
            lock:false,
            hidden:false,
            fontSize:50,
            fontFamily:'fantasy',
            fontWeight:'bold',
            color:'#ffffff',
            w:170,
            h:199.922,
            x:117,
            y:180,
          },
        ],
        lock:false,
        hidden:false,
        w:203,
        h:225,
        x:98.475,
        y:170.975
      }
  
    );
    localStorage.setItem('uploads',JSON.stringify(this.uploads))
    }
      

    reader.readAsDataURL(input.files[0])
    
    
  }
}
deleteUpload(index){
  this.uploads.splice(index,1);
  localStorage.setItem('uploads',JSON.stringify(this.uploads))
}
removeSelectSvg(){
  if(document.querySelectorAll('#product-choosen svg .selected-layer').length){
    document.querySelector('#product-choosen svg .selected-layer').classList.remove('selected-layer');
    this.svgSelected = false;
  }
}
  svgChangeColor(e) {
    if(e.target.ownerSVGElement != undefined && e.target.tagName != 'image'){
      var parent = document.querySelectorAll('#product-choosen svg *');
      // var className = e.target.classList[0];
      if(document.querySelectorAll('#product-choosen svg .selected-layer').length){
        document.querySelector('#product-choosen svg .selected-layer').classList.remove('selected-layer')
      }
      e.target.classList.add('selected-layer')
      this.svgSelected = true;
      this.svgSelectedColor = this.convertColor(e,getComputedStyle(e.target).fill);
      this.svgSelectClass = {
        class:e.target.classList[0],
        tag:e.target.tagName,
        target:e.target,
      };
    }
    else {
      this.removeSelectSvg()
    }

    
  }
  svgChooseColor(e){
    Array.from(document.getElementById('product-choosen').getElementsByClassName('selected-layer') as HTMLCollectionOf<HTMLElement>)[0].style.fill = e.target.value;
    this.productSelected.content = document.getElementById('product-choosen').innerHTML;
    this.customizeProducts[this.productSelected.index].content = this.productSelected.content.replace('selected-layer','');
    this.customizeProducts[this.productSelected.index].change = true
    this.setGroupsInStorage()
  }
  addLayer(product,text){
    if(text==true){
      this.groups.push(
         {
          layers:[
            {
              type:'text',
              upload:false,
              content:'',
              lock:false,
              hidden:false,
              fontSize:50,
              fontFamily:'fantasy',
              fontWeight:'bold',
              color:'#ffffff',
              w:170,
              h:199.922,
              x:117,
              y:180,
            },
          ],
          lock:false,
          hidden:false,
          w:203,
          h:225,
          x:98.475,
          y:170.975
        }
    )
    }else {
      this.groups.push(product)
    }
    this.setGroupsInStorage()
  }
  layerSelected(e){
    var layerSelect = e.target.getAttribute('classselect');
    document.querySelectorAll('.design-content .layer-select').length ? document.querySelector('.design-content .layer-select').classList.remove('layer-select') : ''
    e.target.classList.add('layer-select');
    document.querySelectorAll(`.list-of-layers .layer-list-item-selected`).length ? document.querySelector(`.list-of-layers .layer-list-item-selected`).classList.remove('layer-list-item-selected') : ''
    document.querySelector(`.list-of-layers .${layerSelect}`).classList.add('layer-list-item-selected');

    if(e.target.tagName == 'TEXTAREA'){
      // this.layerSelect.color = this.convertColor(e,getComputedStyle(e.target).color)
      // this.layerSelect.family = getComputedStyle(e.target).fontFamily
      // this.layerSelect.size = Number(getComputedStyle(e.target).fontSize.replace('px',''))
      // this.layerSelect.weight = e.target.style.fontWeight.length > 1 ? e.target.style.fontWeight : getComputedStyle(e.target).fontWeight;
      var textareaArray = layerSelect.split("-");
      var group = Number(textareaArray[1])
      if(textareaArray.length > 2){
        var layer = Number(textareaArray[2])
        this.layerSelect.size = this.groups[group].layers[layer].fontSize;
        this.layerSelect.family = this.groups[group].layers[layer].fontFamily;
        this.layerSelect.weight = this.groups[group].layers[layer].fontWeight;
        this.layerSelect.color = this.groups[group].layers[layer].color;
      }else {
        this.layerSelect.size = this.groups[group].layers[0].fontSize,
        this.layerSelect.family = this.groups[group].layers[0].fontFamily
        this.layerSelect.weight = this.groups[group].layers[0].fontWeight
        this.layerSelect.color = this.groups[group].layers[0].color
        this.layerSelect.weight = this.groups[group].layers[0].fontWeight;
      }
      this.setGroupsInStorage()
    }
  }
  layerSelectedList(e){
    var layerSelect = e.target.getAttribute('classselect');
    document.querySelectorAll(`.list-of-layers .list-of-layers-item.layer-list-item-selected`).length ? document.querySelector(`.list-of-layers .list-of-layers-item.layer-list-item-selected`).classList.remove('layer-list-item-selected') : ''
    document.querySelectorAll('.design-content .layer-select').length ? document.querySelector('.design-content .layer-select').classList.remove('layer-select') : ''
    document.querySelector(`.design-content .${layerSelect}`).classList.add('layer-select');
    document.querySelector(`.list-of-layers .${layerSelect}`).classList.add('layer-list-item-selected');

    if(document.querySelector(`.design-content .${layerSelect}`).tagName == 'TEXTAREA'){
      var color = getComputedStyle(document.querySelector(`.design-content .${layerSelect}`)).color
      this.layerSelect.color = this.convertColor(e,color)
      this.layerSelect.family = getComputedStyle(document.querySelector(`.design-content .${layerSelect}`)).fontFamily
      this.layerSelect.size = Number(getComputedStyle(document.querySelector(`.design-content .${layerSelect}`)).fontSize.replace('px',''))
      this.layerSelect.weight = Array.from(document.getElementsByClassName('design-content')[0].getElementsByClassName(`${layerSelect}`) as HTMLCollectionOf<HTMLElement>)[0].style.fontWeight.length > 1 ? Array.from(document.getElementsByClassName('design-content')[0].getElementsByClassName(`${layerSelect}`) as HTMLCollectionOf<HTMLElement>)[0].style.fontWeight : getComputedStyle(e.target).fontWeight

    }
    
    this.removeSelectSvg()
  }
  textColor(e){
    if(document.querySelector('.design-content textarea.layer-select') != undefined){
      var layerSelect = document.querySelector('.design-content textarea.layer-select').getAttribute('classselect')
      var textareaArray = layerSelect.split("-");
      var group = Number(textareaArray[1])
      Array.from(document.getElementsByClassName('design-content')[0].getElementsByClassName('layer-select') as HTMLCollectionOf<HTMLElement>)[0].style.color = e.target.value
      if(textareaArray.length > 2){
        var layer = Number(textareaArray[2])
        this.groups[group].layers[layer].color = e.target.value
      }else {
        this.groups[group].layers[0].color = e.target.value
      }
    }
    this.setGroupsInStorage()
    
      this.removeSelectSvg()
  }
  textSize(e){

    if(document.querySelector('.design-content textarea.layer-select') != undefined){
      var layerSelect = document.querySelector('.design-content textarea.layer-select').getAttribute('classselect')
      var textareaArray = layerSelect.split("-");
      var group = Number(textareaArray[1])
      Array.from(document.getElementsByClassName('design-content')[0].getElementsByClassName('layer-select') as HTMLCollectionOf<HTMLElement>)[0].style.fontSize = e.target.value + 'px'
      if(textareaArray.length > 2){
        var layer = Number(textareaArray[2])
        this.groups[group].layers[layer].fontSize = e.target.value
      }else {
        this.groups[group].layers[0].fontSize = e.target.value
      }
    }
    this.setGroupsInStorage()
    
      this.removeSelectSvg()


  }
  textFamily(e){
    if(e.target.value != ''){
      if(document.querySelector('.design-content textarea.layer-select') != undefined){
        var layerSelect = document.querySelector('.design-content textarea.layer-select').getAttribute('classselect')
        var textareaArray = layerSelect.split("-");
        var group = Number(textareaArray[1])
        Array.from(document.getElementsByClassName('design-content')[0].getElementsByClassName('layer-select') as HTMLCollectionOf<HTMLElement>)[0].style.fontFamily = e.target.value
        if(textareaArray.length > 2){
          var layer = Number(textareaArray[2])
          this.groups[group].layers[layer].fontFamily = e.target.value
        }else {
          this.groups[group].layers[0].fontFamily = e.target.value
        }
      }
    }
    this.setGroupsInStorage()
    
      this.removeSelectSvg()

  }
  textWeight(e){

    if(e.target.value != ''){
      if(document.querySelector('.design-content textarea.layer-select') != undefined){
        var layerSelect = document.querySelector('.design-content textarea.layer-select').getAttribute('classselect')
        var textareaArray = layerSelect.split("-");
        var group = Number(textareaArray[1])
        Array.from(document.getElementsByClassName('design-content')[0].getElementsByClassName('layer-select') as HTMLCollectionOf<HTMLElement>)[0].style.fontWeight = e.target.value
        if(textareaArray.length > 2){
          var layer = Number(textareaArray[2])
          this.groups[group].layers[layer].fontWeight = e.target.value
        }else {
          this.groups[group].layers[0].fontWeight = e.target.value
        }
      }
    }
    this.setGroupsInStorage()
    
      this.removeSelectSvg()
  }
  mouseMove(e,type,group,layer){
    var transform =  e.target.style.transform.replace(/translate\(/,'').replace(/\)/,'').split(",");
    var dimintion = {
      x:transform[0].replace('px',''),
      y:transform[1].replace('px',''),
      w:e.target.style.width.replace('px',''),
      h:e.target.style.height.replace('px',''),

    }
    if(type == 'parent' && e.target.children.length > 0){
      this.groups[group].x = dimintion.x;
      this.groups[group].y = dimintion.y;
      this.groups[group].w = dimintion.w;
      this.groups[group].h = dimintion.h;
    }else if(layer == undefined && type != 'parent'){
      this.groups[group].layers[0].x = dimintion.x;
      this.groups[group].layers[0].y = dimintion.y;
      this.groups[group].layers[0].w = dimintion.w;
      this.groups[group].layers[0].h = dimintion.h;
    } else if(layer != undefined && type != 'parent' && e.target.children.length == 0){
      
      this.groups[group].layers[layer].x = dimintion.x;
      this.groups[group].layers[layer].y = dimintion.y;
      this.groups[group].layers[layer].w = dimintion.w;
      this.groups[group].layers[layer].h = dimintion.h;
    }
    
    this.setGroupsInStorage()
  }
  deleteLayer(group,layer){
    if(layer == undefined){
      this.groups.splice(group,1)
    }else {
      this.groups[group].layers.splice(layer,1)
    }
    this.setGroupsInStorage()
    
      this.removeSelectSvg()
  }
  lockLayer(group,layer){
    if(layer == undefined){
      this.groups[group].lock = !this.groups[group].lock;
      this.groups[group].layers.forEach(layer => {
        layer.lock = !layer.lock;
      });

    }else {
      this.groups[group].layers[layer].lock = !this.groups[group].layers[layer].lock;
    }
    this.setGroupsInStorage()
    
      this.removeSelectSvg()
  }
  hiddenLayer(group,layer){
    if(layer == undefined){
      this.groups[group].hidden = !this.groups[group].hidden;
      this.groups[group].layers.forEach(layer => {
        layer.hidden = !layer.hidden;
      })
    }else {
      this.groups[group].layers[layer].hidden = !this.groups[group].layers[layer].hidden;
    }
    this.setGroupsInStorage()
    
      this.removeSelectSvg();

  }
  html(id,code){
    var parent = document.getElementById(id) ;
    var product =  document.getElementById(id).getAttribute('product')
    parent.innerHTML= code;
   
    
  }
  htmlWithAccess(id,code,index){
    var parent = document.getElementById(id) ;
    var product =  document.getElementById(id).getAttribute('product')

    document.getElementById(id).innerHTML= code;
    
    
  }
  selectproduct(index){
    this.customizeProducts[index].selected = true;
    this.productSelected.index = index;
    document.getElementById('product-choosen').innerHTML= this.customizeProducts[index].content;
    this.productSelected.groups = this.customizeProducts[index].groups;
    this.product = this.customizeProducts[this.productSelected.index];  
    this.setGroupsInStorage()
  }
  deleteproduct(index){
    if(this.productSelected.index == index){
      this.customizeProducts[index].selected = false;
      this.productSelected.index = '';
      document.getElementById('product-choosen').innerHTML= '';
      this.productSelected.groups = [];
    } else if(this.productSelected.index > index) {
      this.customizeProducts[index].selected = true;
      this.productSelected.index = (Number(this.productSelected.index) - 1).toString();
    }else if(this.productSelected.index < index) {
      this.customizeProducts[index].selected = true;
      this.productSelected.index = this.productSelected.index;
    }
    this.customizeProducts.splice(index,1)
    this.setGroupsInStorage()
  }
  addAddition(id,addition){
    var elActive = document.getElementsByClassName('addition-selected');
    if(elActive.length){
      elActive[0].classList.remove('addition-selected')
    }
    document.getElementById(id).classList.add('addition-selected')
  }
  chooseWrapping(id){
    var elActive = document.getElementsByClassName('wrapping-selected');
    if(elActive.length){
      elActive[0].classList.remove('wrapping-selected')
    }
    document.getElementById(id).classList.add('wrapping-selected')
  }
  constructor(private http: HttpClient,private route:ActivatedRoute ,private sanitizer: DomSanitizer) {
    this.http.get('/assets/products.json').subscribe(data =>{
      this.products = data;
    });
   }

  ngOnInit(): void {
    // this.product = 'this.route.params';
    this.svg = this.sanitizer
    
  }

}
