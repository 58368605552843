<div class="container template mt-5">
    <div class="row">
        <div class="col-md-4">
            <div class="tabs tabs-horizontal-left tabs-accent row">
                <ul class="nav nav-tabs col-md-2" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#products" role="tab" aria-selected="true">
                            <i class="h3 mdi-view-module"></i>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#templates" role="tab" aria-selected="true">
                            <i class="h3 mdi-credit-card-multiple"></i>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#uploads" role="tab" aria-selected="true">
                            <i class="h3 mdi-cloud-upload"></i>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#text" role="tab" aria-selected="false">
                            <i class="h3 mdi-format-text"></i>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#addition" role="tab" aria-selected="false">
                            <i class="h3 mdi-library-plus"></i>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#wrapping" role="tab" aria-selected="false">
                            <i class="h3 mdi-gift"></i>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#layers" role="tab" aria-selected="false">
                            <i class="h3 mdi-layers"></i>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#customizeProducts" role="tab" aria-selected="false">
                            <i class="h3 mdi-format-list-bulleted"></i>
                        </a>
                    </li>
                </ul>
                <div class="tab-content scroll col-md-10">
                    <div class="tab-pane fade show active" id="products" role="tabpanel">
                        <div class="row">
                            <div class="col-md-6 mb-3 product" [id]="'product-'+i"  *ngFor="let product of products;let i=index" (click)="AddProduct(product)">
                                {{html('product-'+i,product.content)}}
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade show" id="templates" role="tabpanel">
                        <div class="row" *ngIf="product">
                            
                            <div class="col-md-6 mb-3" *ngFor="let template of product.templates">
                                <div class="group" (click)="addLayer(template)"  
                                [style]="template.layers.length > 1 ? 'width:'+template.w+'px;height:'+template.h+'px;transform: translate(-36px,10px) scale(0.7);' : ''">
                                    
                                    <ng-container *ngFor="let layer of template.layers">
                                        <ng-container  *ngIf="layer.type== 'img'">
                                            <img class="templates" *ngIf="template.layers.length == 1" 
                                            [src]="[layer.upload == false ? './assets/img/' : '']+layer.content" 
                                            [style]="'width:'+layer.w+'px;height:'+layer.h+'px;'"
                                            >
                                            <img class="templates" *ngIf="template.layers.length > 1" 
                                                [src]="[layer.upload == false ? './assets/img/' : '']+layer.content" 
                                                
                                                [style]="'position:absolute;width:'+layer.w+'px;height:'+layer.h+'px;transform:translate('+layer.x+'px,'+layer.y+'px)'"
                                                >
                                        </ng-container> 
                                        <ng-container  *ngIf="layer.type== 'text'">
                                            <div 
                                            [style]="'position:absolute;width:'
                                            +layer.w+'px;height:'
                                            +layer.h+'px;transform:translate('+layer.x+'px,'+layer.y+'px);color:'
                                            +layer.color
                                            +';font-size:'
                                            +layer.fontSize
                                            +'px;font-family:'
                                            +layer.fontFamily
                                            +';font-weight:'
                                            +layer.fontWeight
                                            ">
                                                {{layer.content}}
                                            </div>
                                        </ng-container> 
                                        
                                    </ng-container>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade show" id="uploads" role="tabpanel">
                        <div class="row">
                            <div class="col-md-6 mb-3 add-photo-group">
                                <input type="file" id="add-teamplate" (change)="uploadImage($event)" name id>
                            </div>
                            <div class="col-md-6 mb-3" *ngFor="let upload of uploads">
                                <ng-container *ngFor="let img of upload.layers;let u = index">
                                    <i class="mdi-delete delete-product" (click)="deleteUpload(u)"></i>
                                    <img class="upload" [src]="img.content" (click)="product ? addLayer(upload) : ''"/>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="text" role="tabpanel">
                        <div class="row">
                            <div class="col-md-12 d-flex justify-content-center">
                                <button class="btn btn-primary mb-3 add-text" id="add-text" (click)="addLayer([],true)">
                                    add New Text
                                    <i class="fa fa-plus"></i>
                                </button>
                            </div>
                            <div class="col-md-12">
                                <div class="mb-3">
                                    <label class="form-label">Family</label>
                                    <div class="input-group mb-3">
                                        <select class="form-control" (change)="textFamily($event)" [(ngModel)]="layerSelect.family">
                                            <option value>Choose Font</option>
                                            <option value="sans-serif">sans-serif</option>
                                            <option value="cursive">cursive</option>
                                            <option value="emoji">emoji</option>
                                            <option value="fantasy">fantasy</option>
                                            <option value="monospace">monospace</option>
                                            <option value="fangsong">fangsong</option>


                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="mb-3">
                                <label class="form-label">Weight</label>
                                    <div class="input-group mb-3">
                                        <select class="form-control" (change)="textWeight($event)" [(ngModel)]="layerSelect.weight">
                                            <option value>Choose Font Weight</option>
                                            <option value="bold">bold</option>
                                            <option value="bolder">bolder</option>
                                            <option value="lighter">lighter</option>
                                            <option value="normal">normal</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                            <option value="300">300</option>
                                            <option value="400">400</option>
                                            <option value="500">500</option>
                                            <option value="600">600</option>
                                            <option value="700">700</option>
                                            <option value="800">800</option>
                                            <option value="900">900</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="mb-3">
                                    <label class="form-label">Size</label>
                                    <div class="input-group mb-3">
                                        <input type="number" class="form-control" min="1" value="50" (change)="textSize($event)" name="font-size" [(ngModel)]="layerSelect.size">
                                        <span class="input-group-text">px</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="mb-3">
                                    <label class="form-label">Color</label>
                                    <div class="input-group mb-3">
                                        <input class="font-color" type="color" (change)="textColor($event)" id="font-color" name="font-color" [(ngModel)]="layerSelect.color">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="addition" role="tabpanel">
                        <div class="row" *ngIf="product">
                            <div class="col-md-6 mb-3" *ngFor="let addition of product.additions; let a = index">
                                <div class="group" [id]="'addition-'+a" (click)="addition.dragDrop == true ? addLayer(addition) : addAddition('addition-'+a,addition)"  
                                [style]="addition.layers.length > 1 ? 'width:'+addition.w+'px;height:'+addition.h+'px;transform: translate(-36px,10px) scale(0.7);' : ''">
                                    
                                    <ng-container *ngFor="let layer of addition.layers">
                                        <ng-container  *ngIf="layer.type== 'img'">
                                            <img class="templates" *ngIf="addition.layers.length == 1" 
                                            [src]="[layer.upload == false ? './assets/img/' : '']+layer.content" 
                                            [style]="'width:'+layer.w+'px;height:'+layer.h+'px;'"
                                            >
                                            <img class="templates" *ngIf="addition.layers.length > 1" 
                                                [src]="[layer.upload == false ? './assets/img/' : '']+layer.content" 
                                                
                                                [style]="'position:absolute;width:'+layer.w+'px;height:'+layer.h+'px;transform:translate('+layer.x+'px,'+layer.y+'px)'"
                                                >
                                        </ng-container> 
                                        <ng-container  *ngIf="layer.type== 'text'">
                                            <div 
                                            [style]="'position:absolute;width:'
                                            +layer.w+'px;height:'
                                            +layer.h+'px;transform:translate('+layer.x+'px,'+layer.y+'px);color:'
                                            +layer.color
                                            +';font-size:'
                                            +layer.fontSize
                                            +'px;font-family:'
                                            +layer.fontFamily
                                            +';font-weight:'
                                            +layer.fontWeight
                                            ">
                                                {{layer.content}}
                                            </div>
                                        </ng-container> 
                                        
                                    </ng-container>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="wrapping" role="tabpanel">
                        <div class="row" *ngIf="product">
                            <div class="col-md-6 mb-3" [id]="'wrapping-'+w"  (click)="chooseWrapping('wrapping-'+w)" *ngFor="let wrapping of product.wrappings; let w = index">
                                <img [src]="'/assets/img/'+wrapping.content" />
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="layers" role="tabpanel">
                        <ul class="list-group layer-list list-of-layers">
                            <li *ngFor="let group of groups;let
              index = index" class="list-group-item list-of-layers-item " [class]="group.layers.length > 1 ? 'group-'+index :'layer-'+index">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span>
                                        {{group.layers.length > 1 ? 'group' :'layer'}}{{index + 1}}
                                    </span>
                                    <div class="action">
                                            <span [class]="[group.hidden == false ? 'animated h3 mdi-eye' : 'animated h3 mdi-eye-off']+''" (click)="hiddenLayer(index)"></span>
                                            <span [class]="[group.lock == false ? 'animated h3 mdi-lock-open' : 'animated h3 mdi-lock']+''" (click)="lockLayer(index)"></span>
                                            <span class="animated h3 mdi-layers" [attr.classSelect]="group.layers.length > 1 ? 'group-'+index :'layer-'+index" (click)="layerSelectedList($event)">
                                            </span>
                                            <span class="animated h3 mdi-table-column-remove" (click)="deleteLayer(index)"></span>
                                        </div>
                                    </div>
                                
                                <ul class="list-group layer-list list-of-layers" *ngIf="group.layers.length > 1">
                                    <li class="list-group-item list-of-layers-item" *ngFor="let layer of group.layers;let l = index" [class]="'layer-'+index+'-'+l" [attr.classSelect]="'layer-'+index+'-'+l">
                                        
                                        <div class="d-flex justify-content-between align-items-center">
                                                <span>
                                                    Layer{{l+1}}
                                                </span>
                                                <div class="action">
                                                        <span [class]="[group.hidden == false || layer.hidden == false ? 'animated h3 mdi-eye' : 'animated h3 mdi-eye-off']+''" (click)="hiddenLayer(index,l)"></span>
                                                        <span [class]="[group.lock == false || layer.lock == false? 'animated h3 mdi-lock-open' : 'animated h3 mdi-lock']+''" (click)="lockLayer(index,l)"></span>
                                                        <span class="animated h3 mdi-layers" [attr.classSelect]="'layer-'+index+'-'+l" (click)="layerSelectedList($event,l)"></span>
                                                        <span class="animated h3 mdi-table-column-remove" (click)="deleteLayer(index,l)"></span>
                                                    </div>
                                        </div>
                                        
                                    </li>
                                </ul>

                            </li>

                        </ul>
                    </div>
                    <div class="tab-pane fade" id="customizeProducts" role="tabpanel">
                        <div class="row">
                            <div class="col-md-6 product"  
                            *ngFor="let customizeProduct of customizeProducts;let i = index " 
                            >
                            <i class="mdi-delete delete-product" (click)="deleteproduct(i)"></i>
                            <div [id]="'customize-product-'+i" (click)="selectproduct(i)">
                                {{html('customize-product-'+i,customizeProduct.content)}}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="panel admin-panel h-100">
                <div class="panel-header">
                    <div class="d-flex align-items-center">
                        <div class="admin-panel-buttons">
                            <button class="admin-panel-button" title="fullscreen" data-panel="fullscreen"></button>
                        </div>
                        <div class="admin-panel-switch fa-bars"></div>
                    </div>
                </div>
                <div class="panel-body">
                    <div class="row justify-content-center">
                        <div class="col-md-12">
                            <div class="color-selected">
                                <input *ngIf="svgSelected" (change)="svgChooseColor($event)" class="color" type="color" id="favcolor" name="favcolor" [(ngModel)]="svgSelectedColor">
                            </div>
                        </div>
                        <div class="col-md-12 d-flex justify-content-center">
                            <div class="design-content svg-content">
                                <ng-container *ngFor="let group of groups ; let index = index">
                                    <div (mousemove)="mouseMove($event,'parent',index)" [class]="[group.lock == false ? 'resize-drag' : '']+[group.hidden == true ? ' d-none' : '']+' resize-drag-item layer group-'+index" *ngIf="group.layers.length > 1" style="position: absolute;" [style]="'transform:translate('+group.x+'px, '+group.y+'px);width:'+group.w+'px;height:'+group.h+'px'"
                                        (click)="layerSelected($event)" [attr.classSelect]=" 'group-'+index  " [attr.width]="group.w" [attr.height]="group.h" [attr.tx]="group.x" [attr.ty]="group.y">
                                        <ng-template ngFor let-layer [ngForOf]="group.layers" let-l="index">
                                            <textarea (mousemove)="mouseMove($event,'child',index,l)" *ngIf="layer.type == 'text'" name id [(ngModel)]="layer.content" [class]=" [layer.lock == false ? 'resize-drag' : '']+[layer.hidden == true || group.hidden == true ? ' d-none' : '']+' resize-drag-item layer layer-'+index+'-'+l"
                                                [attr.classSelect]=" 'layer-'+index+'-'+l" cols="30" rows="10" [style]=" 'transform:translate('
                                                +layer.x+'px, '+layer.y+'px);width:'
                                                +layer.w+'px;height:'
                                                +layer.h+'px;color:'
                                                +layer.color
                                                +';font-size:'
                                                +layer.fontSize
                                                +';font-family:'
                                                +layer.fontFamily
                                                +';font-weight:'
                                                +layer.fontWeight
                                                " 
                                            (click)=" layerSelected($event)" [attr.width]=" layer.w" [attr.height]=" layer.h" [attr.tx]="layer.x" [attr.ty]="layer.y"></textarea>
                                     
                                            <img (mousemove)="mouseMove($event,'child',index,l)" *ngIf="layer.type == 'img'" [src]="[layer.upload == false ? './assets/img/' : '']+layer.content" [class]=" [layer.lock == false ? 'resize-drag' : '']+[layer.hidden == true || group.hidden == true ? ' d-none' : '']+' resize-drag-item layer layer-'+index+'-'+l"
                                                [attr.classSelect]=" 'layer-'+index+'-'+l" alt [style]=" 'transform:translate('+layer.x+'px, '+layer.y+'px);width:'+layer.w+'px;height:'+layer.h+'px'" (click)=" layerSelected($event)" [attr.width]=" layer.w"
                                                [attr.height]=" layer.h" [attr.tx]="layer.x" [attr.ty]="layer.y">
                                        </ng-template>
                                    </div>
                                    <ng-container *ngIf="group.layers.length == 1">
                                        <ng-container *ngFor="let layer of group.layers;let l = index">
                                            <textarea (mousemove)="mouseMove($event,'child',index)" *ngIf="layer.type == 'text'" name id [(ngModel)]="layer.content" [class]="[layer.lock == false ? 'resize-drag' : '']+[layer.hidden == true || group.hidden == true ? ' d-none' : '']+ ' resize-drag-item layer layer-'+index"
                                                [attr.classSelect]="'layer-'+index " cols="30" rows="10" [style]=" 'transform:translate('+layer.x+'px, '+layer.y+'px);width:'+layer.w+'px;height:'+layer.h+'px;color:'
                                                +layer.color
                                                +';font-size:'
                                                +layer.fontSize
                                                +'px;font-family:'
                                                +layer.fontFamily
                                                +';font-weight:'
                                                +layer.fontWeight" 
                                                (click)="layerSelected($event)" [attr.width]=" layer.w " [attr.height]=" layer.h " [attr.tx]="layer.x" [attr.ty]="layer.y"></textarea>
                                            <img (mousemove)="mouseMove($event,'child',index)" *ngIf="layer.type == 'img'" [src]="[layer.upload == false ? './assets/img/' : '']+layer.content" [class]="[layer.lock == false ? 'resize-drag' : '']+[layer.hidden == true || group.hidden == true ? ' d-none' : '']+ ' resize-drag-item layer layer-'+index" [attr.classSelect]="'layer-'+index"
                                                alt [style]="'transform:translate('+layer.x+'px, '+layer.y+'px);width:'+layer.w+'px;height:'+layer.h+'px' " (click)="layerSelected($event)" [attr.width]="layer.w" [attr.height]="layer.h" [attr.tx]="layer.x"
                                                [attr.ty]="layer.y">
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <div (click)="svgChangeColor($event)" id="product-choosen">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
</div>

